import { ROUTE_LAYOUT_CONFIG } from "../constants/LayoutsConfig.constants";
import { matchPath } from "react-router-dom";

/**
 * Finds the matching layout component for the current route.
 *
 * This function iterates through the `ROUTE_LAYOUT_CONFIG` to find the layout
 * component that corresponds to the current `location.pathname`. The function
 * matches the route using `matchPath` with exact matching enabled.
 */
export const findMatchingRouteLayout = (location) => {
  let matchingLayout = null;

  // Iterate through the keys of the ROUTE_LAYOUT_CONFIG
  Object.keys(ROUTE_LAYOUT_CONFIG).forEach((routeId) => {
    if (matchingLayout) return; // Exit early if a match is found

    const match = matchPath(location.pathname, {
      path: routeId, // Match against the route ID in the config
      exact: true,
    });
    if (match) {
      matchingLayout = ROUTE_LAYOUT_CONFIG[routeId]; // Assign the matched layout
    }
  });

  return matchingLayout;
};
