export const WHATSAPP_BROADCAST_CUSTOM_ROUTE_KEYS = {
  WHATSAPP_BROADCAST: "WHATSAPP_BROADCAST",
  CREATE_WHATSAPP_BROADCAST: "CREATE_WHATSAPP_BROADCAST",
  PUBLISH_WHATSAPP_BROADCAST: "PUBLISH_WHATSAPP_BROADCAST",
};

export const WHATSAPP_BROADCAST_ROUTE_IDS = {
  [WHATSAPP_BROADCAST_CUSTOM_ROUTE_KEYS.WHATSAPP_BROADCAST]:
    "/whatsapp-broadcasts",
  [WHATSAPP_BROADCAST_CUSTOM_ROUTE_KEYS.CREATE_WHATSAPP_BROADCAST]:
    "/WhatsappTemplate",
  [WHATSAPP_BROADCAST_CUSTOM_ROUTE_KEYS.PUBLISH_WHATSAPP_BROADCAST]:
    "/whatsapp-broadcasts/publish",
};

export const WHATSAPP_BROADCAST_MAIN_PATH =
  WHATSAPP_BROADCAST_ROUTE_IDS[
    WHATSAPP_BROADCAST_CUSTOM_ROUTE_KEYS.WHATSAPP_BROADCAST
  ];

export const WHATSAPP_BROADCAST_ROUTE_GENERATORS = {
  [WHATSAPP_BROADCAST_CUSTOM_ROUTE_KEYS.CREATE_WHATSAPP_BROADCAST]: ({
    templateUuid,
  }) =>
    `${
      WHATSAPP_BROADCAST_ROUTE_IDS[
        WHATSAPP_BROADCAST_CUSTOM_ROUTE_KEYS.CREATE_WHATSAPP_BROADCAST
      ]
    }/${templateUuid}`,
};
