import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Layout } from "ui/layout";
import { findMatchingRouteLayout } from "./utils/Layouts.utils";

export const Layouts = (props) => {
  const location = useLocation();

  const RenderLayout = useMemo(() => {
    const ActiveLayout = findMatchingRouteLayout(location);
    return ActiveLayout ?? Layout; // Return fallback layout if no match is found
  }, [location.pathname]);

  return <RenderLayout {...props} />;
};
