import ChatRoundedIcon from "@material-ui/icons/ChatRounded";
import { AUTOMATED_COMMS_MAIN_PATH } from "features/AutomatedCommunication/constants/AutomatedComms.urlPaths";
import { EMAIL_BROADCAST_MAIN_PATH } from "features/EmailBroadcast/constants/EmailBroadcast.urlPaths";
import { WHATSAPP_BROADCAST_MAIN_PATH } from "features/WhatsappBroadcast/constants/WhatsappBroadcast.urlPaths";
import React from "react";
import { matchPath, useLocation } from "react-router-dom";
import ParentMenu from "ui/layout/Menu/components/ParentMenu/ParentMenu";
import {
  MENU_ITEM_IDS,
  SUB_MENU_CONFIG,
  TOOGLE_PARENT_MENU_KEYS,
} from "../../constants/Menu.constants";
import { CustomMenuItemLink } from "../CustomMenuItemLink/CustomMenuItemLink";
import { useInboxStore } from "features/OmniChannel/modules/Inbox/store/useInboxStore";
import { useGetInboxNotificationsCount } from "features/OmniChannel/modules/Inbox/apis/getInboxNotificatificationsCount";
import { useSelector } from "react-redux";
import styles from "features/UILayout/modules/Menu/MenuStyles.module.css";
import { returnEdgeElementsFromParentMenu } from "../../utils/Menu.utils";
import { INBOX_MAIN_PATH } from "features/OmniChannel/OmniChannel.urlPaths";
import { MenuBadge } from "../MenuBadge/MenuBadge";

export const Communications = ({
  VISIBLE_MENU_ITEMS_CONFIG,
  open,
  state,
  dense,
  commonMenuItemLinkProps,
  moveToAnotherMenuItem,
  setState,
  handleToggle,
  config,
}) => {
  const location = useLocation();
  const totalUnreadNotifications = useInboxStore(
    (state) => state.totalUnreadNotifications
  );
  const { isToolTipTourActive, activeTooltipIndex } = useSelector(
    (state) => state.uiLayout
  );

  // Fetch inbox notifications count
  useGetInboxNotificationsCount();

  const menuConfig = [
    {
      id: MENU_ITEM_IDS.INBOX,
      label: (
        <div className="tw-flex tw-gap-1 tw-items-center tw-justify-center">
          Inbox{" "}
          {!!totalUnreadNotifications && (
            <div className="tw-flex tw-items-center tw-justify-center tw-rounded-[16px] tw-bg-[#ca0315] tw-px-1 tw-text-white tw-text-center tw-text-[10px] tw-h-4 tw-min-w-4">
              {totalUnreadNotifications}
            </div>
          )}
        </div>
      ),
      path: INBOX_MAIN_PATH,
      show: VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.INBOX],
    },
    {
      id: MENU_ITEM_IDS.AUTOMATED_MESSAGES,
      label: "Manage Notifications",
      path: AUTOMATED_COMMS_MAIN_PATH,
      show: VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.AUTOMATED_MESSAGES],
    },
    {
      id: MENU_ITEM_IDS.WHATSAPP_MARKETING,
      label: "WhatsApp Marketing",
      path: WHATSAPP_BROADCAST_MAIN_PATH,
      show: VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.WHATSAPP_MARKETING],
    },
    {
      id: MENU_ITEM_IDS.EMAIL_MARKETING,
      label: "Email Marketing",
      path: EMAIL_BROADCAST_MAIN_PATH,
      show: VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.EMAIL_MARKETING],
    },
  ];

  const matchResults = menuConfig.some(({ path: url }) =>
    matchPath(location.pathname, {
      path: url,
    })
  );

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      [TOOGLE_PARENT_MENU_KEYS.COMMUNICATIONS]: matchResults,
    }));
  }, [matchResults]);

  const { firstVisibleItem: firstCommunicationsItem } =
    returnEdgeElementsFromParentMenu({
      parentMenuItemsArr:
        SUB_MENU_CONFIG[TOOGLE_PARENT_MENU_KEYS.COMMUNICATIONS],
      visibleItems: VISIBLE_MENU_ITEMS_CONFIG,
    });

  const handleToggleCommunicationsMenu = () =>
    handleToggle(TOOGLE_PARENT_MENU_KEYS.COMMUNICATIONS);

  return (
    <div id="Communications">
      <ParentMenu
        handleToggle={handleToggleCommunicationsMenu}
        isOpen={state.menuCommunications}
        sidebarIsOpen={open}
        name="Communications"
        icon={<ChatRoundedIcon />}
        rightIcon={<MenuBadge text="New" />}
        dense={dense}
        {...(isToolTipTourActive &&
          config[activeTooltipIndex] === firstCommunicationsItem && {
            menuItemClassName: styles.tooltipContainer,
          })}
      >
        {menuConfig.map(
          ({ id, label, path, show }) =>
            show && (
              <CustomMenuItemLink
                key={id}
                id={id}
                to={path}
                primaryText={label}
                handleToggleParent={moveToAnotherMenuItem}
                {...commonMenuItemLinkProps}
              />
            )
        )}
      </ParentMenu>
    </div>
  );
};
