import React from "react";

export const MenuBadge = ({ text = "New" }) => {
  return (
    <div
      className={`tw-px-[4px] tw-py-[3px] tw-gap-[10px] tw-rounded-[11px] tw-text-[10px] tw-font-normal tw-leading-[11px] tw-text-center tw-text-white tw-bg-[linear-gradient(108.96deg,#5e49f1_0%,#eb5ded_100%)]`}
    >
      {text}
    </div>
  );
};
