import { app_route_ids, app_route_keys } from "constants/urlPaths";
import {
  ACCOUNT_ROUTE_IDS,
  ACCOUNT_ROUTE_KEYS,
} from "features/Account/constants/Acoount.urlPaths";
import { AdminPanelLayout } from "features/AdminPanel/modules/AdminPanelLayout/AdminPanelLayout";
import {
  MANAGE_OFFERINGS_ROUTE_IDS,
  MANAGE_OFFERINGS_ROUTE_KEYS,
} from "features/ManageOfferings/constants/ManageOfferings.urlPaths";
import {
  OFFERING_INVOICE_MANAGEMENT_ROUTE_IDS,
  OFFERING_INVOICE_MANAGEMENT_URL_KEYS,
} from "features/OfferingInvoiceManagement/OfferingInvoiceManagement.constants";

/**
 *
 * The `ROUTE_LAYOUT_CONFIG` is used to determine which layout should be applied
 * to a given route. Each key in this object represents a route path, and its
 * value corresponds to the layout component that should be rendered when the
 * user navigates to that route.
 *
 * Usage:
 * - The `findMatchingRouteLayout` function utilizes this config to match the
 *   current route and return the appropriate layout component.
 * - Helps in dynamically assigning layouts to pages without hardcoding them
 *   inside route definitions.
 */

export const ROUTE_LAYOUT_CONFIG = {
  [ACCOUNT_ROUTE_IDS[ACCOUNT_ROUTE_KEYS.MY_ACCOUNT]]: AdminPanelLayout,
  [ACCOUNT_ROUTE_IDS[ACCOUNT_ROUTE_KEYS.PROFILE_DETAILS]]: AdminPanelLayout,
  [ACCOUNT_ROUTE_IDS[ACCOUNT_ROUTE_KEYS.FEATURES]]: AdminPanelLayout,
  [ACCOUNT_ROUTE_IDS[ACCOUNT_ROUTE_KEYS.PAYMENT_OPTIONS]]: AdminPanelLayout,
  [ACCOUNT_ROUTE_IDS[ACCOUNT_ROUTE_KEYS.COMMUNICATIONS]]: AdminPanelLayout,
  [ACCOUNT_ROUTE_IDS[ACCOUNT_ROUTE_KEYS.EDIT_CONTACT_FORM]]: AdminPanelLayout,
  [`/${app_route_ids[app_route_keys.manage_offering]}/:listing_uuid`]:
    AdminPanelLayout,
  [MANAGE_OFFERINGS_ROUTE_IDS[
    MANAGE_OFFERINGS_ROUTE_KEYS.VISIBILITY_AVAILABILITY
  ]]: AdminPanelLayout,
  [`/${app_route_ids[app_route_keys.booking_questions]}`]: AdminPanelLayout,
  [MANAGE_OFFERINGS_ROUTE_IDS[MANAGE_OFFERINGS_ROUTE_KEYS.PIXEL_TRACKING]]:
    AdminPanelLayout,
  [MANAGE_OFFERINGS_ROUTE_IDS[MANAGE_OFFERINGS_ROUTE_KEYS.PAGE_SECTIONS]]:
    AdminPanelLayout,
  [`/${app_route_ids[app_route_keys.order_bump]}/:offeringUuid`]:
    AdminPanelLayout,
  [app_route_ids[app_route_keys.manage_thankyou_upsell]]: AdminPanelLayout,
  [OFFERING_INVOICE_MANAGEMENT_ROUTE_IDS[
    OFFERING_INVOICE_MANAGEMENT_URL_KEYS.offering_invoice_management
  ]]: AdminPanelLayout,
  [`/${
    MANAGE_OFFERINGS_ROUTE_IDS[MANAGE_OFFERINGS_ROUTE_KEYS.OFFERING_FEEDBACK]
  }`]: AdminPanelLayout,
  [`/${
    MANAGE_OFFERINGS_ROUTE_IDS[
      MANAGE_OFFERINGS_ROUTE_KEYS.OFFERING_FEEDBACK_FORM
    ]
  }`]: AdminPanelLayout,
  [`/${
    MANAGE_OFFERINGS_ROUTE_IDS[
      MANAGE_OFFERINGS_ROUTE_KEYS.OFFERING_PUBLISHED_FEEDBACK
    ]
  }`]: AdminPanelLayout,
  [`/${app_route_ids[app_route_keys.offering_variations]}`]: AdminPanelLayout,
  [`/${app_route_ids[app_route_keys.manage_recordings]}`]: AdminPanelLayout,
  [`/${app_route_ids[app_route_keys.course_analytics]}`]: AdminPanelLayout,
  [`/${app_route_ids[app_route_keys.leaderboard]}`]: AdminPanelLayout,
  [`/${MANAGE_OFFERINGS_ROUTE_IDS[MANAGE_OFFERINGS_ROUTE_KEYS.FAQS]}`]:
    AdminPanelLayout,
  [`${app_route_ids[app_route_keys.manage_communications_of_listing]}`]:
    AdminPanelLayout,
};
