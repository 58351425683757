import { RESOURCE_KEYS } from "utils/OrgPermissions";
import {
  DESKTOP_MENU_TOOLTIP_CONFIG,
  MENU_ITEM_IDS,
  MENU_ITEM_VALUES_CONFIG,
  MOBILE_MENU_TOOLTIP_CONFIG,
  SUB_MENU_CONFIG,
} from "../constants/Menu.constants";

/**
 * Returns a configuration object that determines which menu items should be visible based on user permissions, feature availability, and other conditions.
 *
 * @function
 * @param {Object} params - The parameters object.
 * @param {Function} params.hasPermission - A function that checks if a user has permission for a specific action or resource.
 * @param {Object} params.liveFeatures - An object representing live features that are enabled or disabled.
 * @param {boolean} params.showExlyConnect - Flag indicating if the Exly Connect option should be shown.
 * @param {boolean} params.showReferrals - Flag indicating if the Referrals menu should be shown.
 * @param {boolean} params.showReports - Flag indicating if the Reports menu should be shown.
 * @param {boolean} params.showUserReferrals - Flag indicating if the User Referrals menu should be shown.
 * @param {boolean} params.canAccessOrg - Flag indicating if the user can access the organization management section.
 * @param {boolean} params.isMainUser - Flag indicating if the current user is the main user in the organization.
 *
 * @returns {Object} A configuration object where the keys are menu item IDs and the values are booleans indicating whether the menu item should be visible.
 *
 **/
export const getVisibleMenuItemsConfig = ({
  hasPermission,
  liveFeatures,
  showExlyConnect,
  showReferrals,
  showReports,
  showUserReferrals,
  canAccessOrg,
  isMainUser,
}) => {
  const VISIBLE_MENU_ITEMS_CONFIG = {
    [MENU_ITEM_IDS.DASHBOARD]: hasPermission(RESOURCE_KEYS.SECTIONS.DASHBOARD),
    [MENU_ITEM_IDS.LISTINGS]: hasPermission(
      RESOURCE_KEYS.SECTIONS.CREATE_EDIT_LISTINGS
    ),
    [MENU_ITEM_IDS.SALES_PAGES]: hasPermission("sales"),
    [MENU_ITEM_IDS.YOUR_WEBSITE]: hasPermission(
      RESOURCE_KEYS.SECTIONS.YOUR_WEBSITE
    ),
    [MENU_ITEM_IDS.LANDING_PAGES]: hasPermission(
      RESOURCE_KEYS.SECTIONS.LANDING_PAGES
    ),
    [MENU_ITEM_IDS.PAYMENT_PAGES]: hasPermission(
      RESOURCE_KEYS.SECTIONS.PAYMENT_PAGES
    ),
    [MENU_ITEM_IDS.SEO]: hasPermission(RESOURCE_KEYS.SECTIONS.SEO),
    [MENU_ITEM_IDS.TRANSACTIONS]:
      hasPermission("managing_money") &&
      hasPermission(RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS),
    [MENU_ITEM_IDS.BOOKINGS]: hasPermission(RESOURCE_KEYS.SECTIONS.MY_BOOKINGS),
    [MENU_ITEM_IDS.LEADS]: hasPermission(
      RESOURCE_KEYS.SUBSECTIONS.LEADS.WEBPAGE_LEADS
    ),
    [MENU_ITEM_IDS.CUSTOMERS]: hasPermission(
      RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS
    ),
    [MENU_ITEM_IDS.SEGMENTS]: hasPermission(
      RESOURCE_KEYS.SECTIONS.SEGMENTATION
    ),
    [MENU_ITEM_IDS.FAQS]: true,
    [MENU_ITEM_IDS.INBOX]: hasPermission(RESOURCE_KEYS.SECTIONS.INBOX),
    [MENU_ITEM_IDS.WHATSAPP_MARKETING]: hasPermission(
      RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
    ),
    [MENU_ITEM_IDS.EMAIL_MARKETING]: hasPermission(
      RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
    ),
    [MENU_ITEM_IDS.ZOOM_ATTENDANCE]:
      hasPermission(RESOURCE_KEYS.SECTIONS.ZOOM_ATTENDANCE) &&
      liveFeatures?.is_zoom_attendance_enabled,
    [MENU_ITEM_IDS.ATTENDANCE_REGISTER]:
      hasPermission(RESOURCE_KEYS.SECTIONS.ATTENDANCE) &&
      liveFeatures.is_attendance_enabled,
    [MENU_ITEM_IDS.MY_SCHEDULE]: hasPermission(
      RESOURCE_KEYS.SECTIONS.MY_SCHEDULE
    ),
    [MENU_ITEM_IDS.CERTIFICATES]:
      hasPermission(RESOURCE_KEYS.SECTIONS.CERTIFICATES) || isMainUser,
    [MENU_ITEM_IDS.FEEDBACKS]: hasPermission(RESOURCE_KEYS.SECTIONS.FEEDBACKS),
    [MENU_ITEM_IDS.EXLY_CONNECT]: showExlyConnect,
    [MENU_ITEM_IDS.MANAGE_STAFF]: canAccessOrg,
    [MENU_ITEM_IDS.REPORTS]: showReports,
    [MENU_ITEM_IDS.AUTOMATED_MESSAGES]: hasPermission(
      RESOURCE_KEYS.SUBSECTIONS.COMMUNICATIONS.AUTOMATED_MESSAGES
    ),
    [MENU_ITEM_IDS.BLOG]:
      hasPermission(RESOURCE_KEYS.SECTIONS.BLOGS) &&
      liveFeatures?.is_blogs_active,
    [MENU_ITEM_IDS.MEDIA_LIBRARY]:
      liveFeatures?.enable_media_library &&
      hasPermission(RESOURCE_KEYS.SECTIONS.MEDIA_LIBRARY),
    [MENU_ITEM_IDS.INTEGRATIONS]: hasPermission(
      RESOURCE_KEYS.SECTIONS.INTEGRATIONS
    ),
    [MENU_ITEM_IDS.REFER_A_FRND]: showReferrals,
    [MENU_ITEM_IDS.USER_REFERALS]: showUserReferrals,
    [MENU_ITEM_IDS.GETTING_STARTED]: true,
  };

  return VISIBLE_MENU_ITEMS_CONFIG;
};

/**
 * Returns the first and last visible menu items from a given list of parent menu items based on the visibility configuration.
 *
 * @function
 * @param {Object} params - The parameters object.
 * @param {Array} params.parentMenuItemsArr - An array of parent menu item IDs to check.
 * @param {Object} params.visibleItems - A visibility configuration object that maps menu item IDs to booleans indicating their visibility.
 *
 * @returns {Object} An object containing the first and last visible menu items.
 * @returns {string|undefined} firstVisibleItem - The first visible menu item from the list.
 * @returns {string|undefined} lastVisibleItem - The last visible menu item from the list.
 *
 * Purpose of edge elements
 * First element : used to add css to the parent menu, Requirement : parent menu needs to be highlighted when first sub menu tooltip is active
 * Last Element  : used to collapse the current parent menu and open the next one.
 **/
export const returnEdgeElementsFromParentMenu = ({
  parentMenuItemsArr = [],
  visibleItems = [],
}) => {
  const VISIBLE_MENU_ITEMS_CONFIG = visibleItems || [];
  const visibleMenuItems =
    parentMenuItemsArr.filter((item) => VISIBLE_MENU_ITEMS_CONFIG[item]) || [];

  // Get the first and last visible items
  const firstVisibleItem = visibleMenuItems[0];
  const lastVisibleItem = visibleMenuItems[visibleMenuItems.length - 1];

  return { firstVisibleItem, lastVisibleItem };
};

// Utility function to get the sequence index
export const getDesktopTooltipSequenceIndex = (menuItemId) => {
  return DESKTOP_MENU_TOOLTIP_CONFIG.indexOf(menuItemId);
};

// Utility function to get the sequence index
export const getMobileTooltipSequenceIndex = (menuItemId) => {
  return MOBILE_MENU_TOOLTIP_CONFIG.indexOf(menuItemId);
};

// find the parent manu key , if provided sub menu item
export const findParentMenuKey = (item) => {
  // Iterate over each key-value pair in the menuObject
  for (const [key, value] of Object.entries(SUB_MENU_CONFIG)) {
    if (value.includes(item)) {
      return key; // Return the key if the item is found
    }
  }
  return null; // Return null if the item is not found
};

export const getSortedTooltipIDs = ({
  tooltipsConfig = MENU_ITEM_VALUES_CONFIG,
  key,
}) => {
  // Convert the object into an array of values
  const tooltipArray = Object.values(tooltipsConfig);

  // Sort the array based on key
  tooltipArray.sort((a, b) => a[key] - b[key]);

  // Map the sorted array to extract only the id field
  return tooltipArray.map((item) => item.id);
};
