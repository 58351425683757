import React from "react";
import { matchPath, useLocation } from "react-router-dom";
import StoreMallDirectoryRoundedIcon from "@material-ui/icons/StoreMallDirectoryRounded";
import ParentMenu from "ui/layout/Menu/components/ParentMenu/ParentMenu";
import { CustomMenuItemLink } from "../CustomMenuItemLink/CustomMenuItemLink";
import {
  MENU_ITEM_IDS,
  SUB_MENU_CONFIG,
  TOOGLE_PARENT_MENU_KEYS,
} from "../../constants/Menu.constants";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { RESOURCE_KEYS } from "utils/OrgPermissions";
import { MenuBadge } from "../MenuBadge/MenuBadge";

export const Storefront = ({
  has_permission,
  returnEdgeElementsFromParentMenu,
  VISIBLE_MENU_ITEMS_CONFIG,
  handleToggle,
  state,
  setState,
  dense,
  checks,
  isToolTipTourActive,
  config,
  open,
  activeTooltipIndex,
  moveToAnotherMenuItem,
  commonMenuItemLinkProps,
  styles,
}) => {
  const location = useLocation();

  const pathNames = [
    `/${app_route_ids[app_route_keys.manage_webpage]}`,
    `/${app_route_ids[app_route_keys.manage_webpage_templates]}`,
    `/${app_route_ids[app_route_keys.sales_pages_home]}`,
    `/${app_route_ids[app_route_keys.checkout_page_listing]}`,
    `${app_route_ids[app_route_keys.seo_categories]}`,
  ];
  const matchResults = pathNames.some((url) =>
    matchPath(location.pathname, {
      path: url,
    })
  );

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      [TOOGLE_PARENT_MENU_KEYS.STOREFRONT]: matchResults,
    }));
  }, [matchResults]);

  if (!has_permission(RESOURCE_KEYS.SECTIONS.STOREFRONT)) {
    return <></>;
  }

  const { firstVisibleItem: firstStorefrontItem } =
    returnEdgeElementsFromParentMenu({
      parentMenuItemsArr: SUB_MENU_CONFIG[TOOGLE_PARENT_MENU_KEYS.STOREFRONT],
      visibleItems: VISIBLE_MENU_ITEMS_CONFIG,
    });

  const toggleStorefrontMenu = () =>
    handleToggle(TOOGLE_PARENT_MENU_KEYS.STOREFRONT);

  return (
    <div id="Storefront">
      <ParentMenu
        handleToggle={toggleStorefrontMenu}
        isOpen={state.menuStorefront}
        sidebarIsOpen={open}
        name="Storefront"
        icon={<StoreMallDirectoryRoundedIcon />}
        rightIcon={<MenuBadge text="New" />}
        dense={dense}
        {...(isToolTipTourActive &&
          config[activeTooltipIndex] === firstStorefrontItem && {
            menuItemClassName: styles.tooltipContainer,
          })}
      >
        {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.YOUR_WEBSITE] && (
          <CustomMenuItemLink
            to={
              checks?.has_selected_webpage_template
                ? app_route_ids[app_route_keys.manage_webpage]
                : app_route_ids[app_route_keys.manage_webpage_templates]
            }
            id={MENU_ITEM_IDS.YOUR_WEBSITE}
            primaryText="Home Page"
            handleToggleParent={moveToAnotherMenuItem}
            {...commonMenuItemLinkProps}
          />
        )}
        {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.LANDING_PAGES] && (
          <CustomMenuItemLink
            to={"/" + app_route_ids[app_route_keys.sales_pages_home]}
            id={MENU_ITEM_IDS.LANDING_PAGES}
            primaryText="Landing Pages"
            handleToggleParent={moveToAnotherMenuItem}
            {...commonMenuItemLinkProps}
          />
        )}
        {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.PAYMENT_PAGES] && (
          <CustomMenuItemLink
            to={"/" + app_route_ids[app_route_keys.checkout_page_listing]}
            id={MENU_ITEM_IDS.PAYMENT_PAGES}
            primaryText="Checkout Pages"
            handleToggleParent={moveToAnotherMenuItem}
            {...commonMenuItemLinkProps}
          />
        )}
        {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.SEO] && (
          <CustomMenuItemLink
            to={app_route_ids[app_route_keys.seo_categories]}
            id={MENU_ITEM_IDS.SEO}
            primaryText="SEO & Accessibility"
            handleToggleParent={moveToAnotherMenuItem}
            {...commonMenuItemLinkProps}
          />
        )}
      </ParentMenu>
    </div>
  );
};
