import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV,
  EXLY_GLOBAL__SET_SHOW_APP_BAR,
  EXLY_GLOBAL__SET_PAGE_TITLE,
  EXLY_GLOBAL__CUSTOM_BACK_NAVIGATION,
} from "redux/actions";
import useDesktopMediaQuery from "./useDesktopMediaQuery";
import { isDefined } from "utils/validations";
import useLearnMoreCtaOnMobileAppbar from "features/UILayout/utils/useLearnMoreCtaOnMobileAppbar";

/**
 * Use to control hide/show App Bar and gives title to the Mobile Page
 * @param { values:{title: string, showBottomNav: bool} , control: { bottomNav: bool, appBar: bool, title: bool } },
 * @returns
 */

const useAppLayoutControl = (args) => {
  const dispatch = useDispatch();
  const is_desktop = useDesktopMediaQuery();

  const control = args?.control || {};
  const values = args?.values || {};

  const appBarControl = control.appBar;

  const valueOfPageTitle = values.title;
  const valueOfBottomNav = values.showBottomNav;
  const customBackAction = values.customBackAction;
  const learnMoreHref = values.learnMoreHref;

  useLearnMoreCtaOnMobileAppbar({ href: learnMoreHref });

  useEffect(() => {
    if (isDefined(control?.bottomNav) || isDefined(valueOfBottomNav)) {
      dispatch({
        type: EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV,
        payload: valueOfBottomNav,
      });
    }

    return () => {
      if (isDefined(control?.bottomNav) || isDefined(valueOfBottomNav)) {
        dispatch({
          type: EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV,
          payload: !valueOfBottomNav,
        });
      }
    };
  }, [valueOfBottomNav]);

  useEffect(() => {
    if (control.title && valueOfPageTitle) {
      dispatch({
        type: EXLY_GLOBAL__SET_PAGE_TITLE,
        payload: values?.title,
      });
    }
  }, [valueOfPageTitle]);

  useEffect(() => {
    if (customBackAction) {
      dispatch({
        type: EXLY_GLOBAL__CUSTOM_BACK_NAVIGATION,
        payload: customBackAction,
      });
    }
  }, [customBackAction]);

  useEffect(() => {
    if (isDefined(appBarControl)) {
      dispatch({ type: EXLY_GLOBAL__SET_SHOW_APP_BAR, payload: is_desktop });
    }

    return () => {
      if (isDefined(appBarControl)) {
        dispatch({ type: EXLY_GLOBAL__SET_SHOW_APP_BAR, payload: is_desktop });
      }
    };
  }, [is_desktop]);

  const resetAppLayout = () => {
    dispatch({ type: EXLY_GLOBAL__SET_PAGE_TITLE, payload: "" });
    dispatch({ type: EXLY_GLOBAL__SET_SHOW_APP_BAR, payload: true });
    dispatch({ type: EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV, payload: true });
    dispatch({
      type: EXLY_GLOBAL__CUSTOM_BACK_NAVIGATION,
      payload: null,
    });
  };

  return {
    resetAppLayout,
  };
};

export default useAppLayoutControl;
