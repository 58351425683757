import { useDispatch, useSelector } from "react-redux";
import { useDesktopMediaQuery } from "features/Common/hooks/useDesktopMediaQuery";
import { setSidebarVisibility } from "react-admin";
import {
  UI_LAYOUT__END_TOOL_TIP_TOUR,
  UI_LAYOUT__SET_HAS_SEEN_TOUR_ONCE,
  UI_LAYOUT__START_TOOL_TIP_TOUR,
} from "features/UILayout/redux/UILayout.actions";
import { TRIGGER_RESIZE_THROTTLE_TIME } from "features/UILayout/modules/Menu/constants/Menu.constants";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";
import { updateFeatureOnboardingFlagV2 } from "features/FeatureOnboardingV2/utils/FeatureOnboardingV2.utils";
import { logError } from "utils/error";

export const useOnboardingTooltip = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);

  const isDesktop = useDesktopMediaQuery();
  const hasSeenCtTour = useSelector((state) => state.uiLayout.hasSeenCtTour);

  const toggleMenu = (value) => dispatch(setSidebarVisibility(value));
  const startOnboardingTour = () => {
    if (!open && isDesktop) {
      toggleMenu(true);
      let resizeTimeout;
      clearTimeout(resizeTimeout);
      // MANUALLY TRIGGER RESIZE EVENT TO ADJUST PLACEMENT OF TOOLTIP WHEN SIDE MENU WAS NOT OPENED AND TOOL TIP TOUR IS STARTED
      resizeTimeout = setTimeout(() => {
        dispatch({ type: UI_LAYOUT__START_TOOL_TIP_TOUR });
        window.dispatchEvent(new Event("resize"));
      }, TRIGGER_RESIZE_THROTTLE_TIME); // Timeout is needed as, Menu items takes some time to collapse and resize should be triggered after that time.
    } else {
      dispatch({ type: UI_LAYOUT__START_TOOL_TIP_TOUR });
    }
  };

  const endOnboardingTooltipTour = async () => {
    try {
      dispatch({ type: UI_LAYOUT__END_TOOL_TIP_TOUR });

      if (!hasSeenCtTour) {
        dispatch({
          type: UI_LAYOUT__SET_HAS_SEEN_TOUR_ONCE,
          payload: true,
        });

        await updateFeatureOnboardingFlagV2({
          featureKey: onboardingFeatureKeys.HAS_SEEN_CT_TOUR,
          value: true,
        });
      }

      if (!isDesktop) toggleMenu(false);
    } catch (error) {
      logError({
        error,
        when: "Failed to update onboarding flag during tooltip tour end:",
        occuredAt:
          "calling endOnboardingTooltipTour in src/features/UILayout/modules/Menu/modules/OnboardingTooltip/utils/useOnboardingTooltip.js",
      });
    }
  };

  return { startOnboardingTour, endOnboardingTooltipTour };
};
